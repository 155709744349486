<template>
  <div>
    <Fabric class="bg-black absolute top-0 bottom-0 left-0 right-0 w-full h-full" v-if="!loading" :id="slide.id" :content="slide.content" :concept="concept"  :concept_id="slide.concept_id" :edit="true" :fullscreen="true" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import store from "@/store/index";
import FlashMessage from "@/components/FlashMessage";
import BasePagination from "@/components/BasePagination";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes, faArrowLeft, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import BaseBtn from "@/components/BaseBtn";
import Fabric from "@/components/Fabric";

library.add(faTimes, faArrowLeft, faPen);

export default {
  name: "SlideEdit",
  components: { FlashMessage, BasePagination, FontAwesomeIcon, BaseBtn, Fabric },
  computed: {
    ...mapGetters("concept", ["concept"]),
    ...mapState("slide", ["loading", "slide"])
  },
  data() {
    return {
      message: "",
      fullscreen: true,
      teleport: true,
      content: {},
      loaded: false,
      //concept: {}
    };
  },
  watch: {
    slide: {
      handler: function (value) {
          this.content = value
      },
    },
  },
  mounted() {
      
  },
  async created() {
    this.slide_id = this.$route.params.slide_id;

    this.getSlide();

    this.$eventBus.$on("slides:updated", (val) => {
      //this.getSlides();
      this.message = "Concept updated";
    });
  },
  methods: {
     
    async getSlide() {
      await this.$store.dispatch("slide/getSlide", this.slide_id).then(() => {
          this.getConcept(this.slide.concept_id) 
        })
      
    },
    getConcept(concept_id) {
      this.$store.dispatch("concept/getConcept", concept_id);

      console.log("concept", this.concept);
    },
  },
};
</script>
