<template>
  <div class="p-5 xl:px-0">
    <div class="flex items-center mb-4">
      <a :href="'/topic/' + concept.topic_id">
        <font-awesome-icon icon="arrow-left" />
      </a>
      <h1 class="text-xl text-center flex-grow" v-html="concept.title" v-if="concept"></h1>
    </div>

    <transition name="fade" mode="out-in">
      <div class="bg-white shadow sm:rounded-md divide-y divide-gray-200" v-if="slides.length">
        <div role="list" v-for="(slide, i) in slides" :key="slide.id" class="flex pr-6 lg:space-x-5 p-10" :id="'slide-' + slide.id">
          <div class="flex flex-col justify-center items-center space-y-4">
            <Fabric v-if="concept" class="flex flex-col" :id="slide.id" :content="slide.content" :concept_id="Number(slide.concept_id)" :index="i" :concept="concept" :slides="slides" :edit="false" />
            <div class="inline-flex button-group">
              <button class="button-l" @click="$router.push(`/concept/${concept_id}/slide/${slide.id}`)" v-permission="['slides.view']">
                <font-awesome-icon icon="fa-solid fa-graduation-cap" class="mx-1" />
              </button>
              <button class="button-m" @click="$router.push(`/concept/${concept_id}/slide-edit/${slide.id}`)" v-permission="['slides.edit']">
                <font-awesome-icon icon="fa-solid fa-pen" class="mx-1" />
              </button>

              <button class="button-m" @click="toggleFav(slide.id, slide.favourite, i)">
                <font-awesome-icon icon="fa-solid fa-star" class="w-8" v-if="slide.favourite" />
                <font-awesome-icon icon="fa-regular fa-star" class="w-8" v-else />
              </button>

              <div class="flex justify-center" >
                <div>
                  <div class="dropdown relative">
                    <button  @click="toggleDropdown(i)" class="flex flex-row items-center h-10" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      <font-awesome-icon icon="fa-solid fa-plus" />
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" class="w-2 ml-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                        <path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"></path>
                      </svg>
                    </button>
                    <ul :class="dropdownShow[i] ? 'block' : 'hidden'" class="dropdown-menu min-w-max absolute bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 m-0 bg-clip-padding border-none" aria-labelledby="dropdownMenuButton1">
                      <li>
                        <modal title="slide" class="cursor-pointer" component="Slide" width="lg" :attributes="{ concept_id: concept_id }" buttonClass="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"> New Slide </modal>
                      </li>
                      <li>
                        <a class="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100" href="#" @click="duplicateSlide(slide)">Duplicate</a>
                      </li>
                      <li>
                        <modal title="Add slide from favourites" class="cursor-pointer" component="AddSlideFavs" width="lg" :attributes="{ concept_id: concept_id }" buttonClass="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"> Add from favourites</modal>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <modal title="Are you sure?" class="cursor-pointer button-r" buttonClass="text-red-600" component="DeleteConfirm" :attributes="{ concept_id: concept_id, message: 'Are you sure you want to delete this slides?', id: slide.id, resource: 'slides' }" v-permission="['slides.delete']">
                <font-awesome-icon icon="times" class="mx-1" v-permission="['slides.delete']" />
              </modal>
            </div>
          </div>

          <div class="flex-grow">
            <div v-if="slide.relevances.length > 0">
              <h4 class="text-xl">Relevance:</h4>
              <ul class="list-disc pl-4 mb-4">
                <li v-for="(r, ri) in slide.relevances" :key="ri" v-text="r.text"></li>
              </ul>
            </div>

            <div v-if="slide.deliveries.length > 0">
              <h4 class="text-xl">Delivery:</h4>
              <ul class="list-decimal pl-4 mb-4">
                <li v-for="(r, ri) in slide.deliveries" :key="ri" v-text="r.text"></li>
              </ul>
            </div>

            <span v-html="slide.text" class="mb-3"></span>
          </div>

          <div class="ml-5 flex-shrink-0">
            <div class="flex">
              <modal title="Slide" class="cursor-pointer" component="Slide" :attributes="{ concept_id: concept_id, topic_id: slide.topic_id, payload: slide }" width="lg" buttonClass="mx-1" v-permission="['slides.edit']">
                <font-awesome-icon icon="pen" />
              </modal>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <p class="mb-4">There are no slides for this key concept yet</p>

        <div class="dropdown relative">
          <button @click="toggleDropdown(0)" class="button flex flex-row items-center h-10" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <span class="mr-2">Add new slide</span>
            <font-awesome-icon icon="fa-solid fa-plus" />
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" class="w-2 ml-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"></path>
            </svg>
          </button>
          <ul :class="dropdownShow[0] ? 'block' : 'hidden'" class="dropdown-menu min-w-max absolute bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 m-0 bg-clip-padding border-none" aria-labelledby="dropdownMenuButton1">
            <li>
              <modal title="slide" class="cursor-pointer" component="Slide" width="lg" :attributes="{ concept_id: concept_id }" buttonClass="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"> New Slide </modal>
            </li>
            <li>
              <modal title="Add slide from favourites" class="cursor-pointer" component="AddSlideFavs" width="lg" :attributes="{ concept_id: concept_id }" buttonClass="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"> Add from favourites</modal>
            </li>
          </ul>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <FlashMessage :message="message" v-if="message" />
      <FlashMessage :error="error" v-if="error" key="error" />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store/index";
import FlashMessage from "@/components/FlashMessage";
import BasePagination from "@/components/BasePagination";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faStar as faStarReg } from "@fortawesome/pro-regular-svg-icons";
import { faTimes, faArrowLeft, faPen, faStar } from "@fortawesome/free-solid-svg-icons";
import { faArrowsMaximize, faGraduationCap } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import BaseBtn from "@/components/BaseBtn";
import Fabric from "@/components/Fabric";
import { component } from "vue-fullscreen";
import SlideService from "@/services/SlideService";
import { getError } from "@/utils/helpers";

library.add(faTimes, faArrowLeft, faPen, faArrowsMaximize, faStar, faStarReg, faGraduationCap);

export default {
  name: "ConceptView",
  components: { FlashMessage, BasePagination, FontAwesomeIcon, BaseBtn, Fabric, VueFullscreen: component },
  computed: {
    ...mapGetters("concept", ["loading", "error", "slides", "concept", "meta", "links"]),
  },
  data() {
    return {
      concept_id: "",
      message: "",
      fullscreen: false,
      teleport: false,
      dropdownShow: [],
    };
  },
  created() {
    this.concept_id = this.$route.params.concept_id;

    this.getSlides();
    this.getConcept();

    this.$eventBus.$on("slides:updated", (val) => {
      this.getSlides();
      this.message = "Slide updated";
    });
    this.$eventBus.$on("slide:updated", (val) => {
      this.getSlides();
      this.message = "Slide updated";
    });
    this.$eventBus.$on("slide:added", (val) => {
      this.getSlides();
      this.message = "Slide added";
    });
    this.$eventBus.$on("slides:deleted", (val) => {
      this.getSlides();
      this.message = "Slide deleted";
    });
  },
  methods: {
    setFullScreen() {
      this.fullscreen = !this.fullscreen;
    },
    toggleFav(slide_id, favourite, index) {
      let payload = {};
      payload.id = slide_id;
      favourite = !favourite;

      SlideService.updateFav(payload)
        .then(() => {
          this.message = "Favourite updated.";
          this.$eventBus.$emit("slide:updated", true);
        })
        .catch((error) => (this.error = getError(error)));

      this.slides[index].favourite = favourite;
    },
    toggleDropdown(slide_id) {
      this.dropdownShow[slide_id] = !this.dropdownShow[slide_id];
      this.$forceUpdate();
    },
    duplicateSlide(slide) {
      slide.sort_order = Number(slide.sort_order) + 1;

      SlideService.add(slide)
        .then(() => {
          this.message = "Slide added.";
          for (let index = 0; index < this.slides.length; index++) {
            this.dropdownShow[index] = false;
          }

          this.$eventBus.$emit("slide:added", true);
        })
        .catch((error) => console.error(error));
    },
    async getSlides() {
      await this.$store.dispatch("concept/getSlides", this.concept_id);

      for (let index = 0; index < this.slides.length; index++) {
        this.dropdownShow[index] = false;
      }
    },
    getConcept() {
      this.$store.dispatch("concept/getConcept", this.concept_id);
    },
  },
};
</script>
